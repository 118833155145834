import React, { useContext, useEffect, useState } from 'react'
import Lang from '../Lang/Lang';
import {eventEmitter, hoverBtnClass, lang, analytics} from '../../common/baseData';
import {
  BasketContext,
  UserContext,
} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';

function Product(props) {
  const {
    BASE_PRICE, BASE_PRICE_FORMATED, CORP_PRICE, CORP_PRICE_FORMATED, PROPERTY_CASHBACK_VALUE, last, itemKey, gaParams,
    ID, IMAGE_379_SRC, IS_NEW, IS_TOP, SPECIAL_DEAL, IS_INCLUDED_IN_DEAL, URL,
    DISCOUNT_TOTAL_PERCENT, DISCOUNT_TOTAL_PERCENT_FORMATED, CASHBACK_FORMATED, DIFF_FORMATED,
    PROPERTY_CML2_ARTICLE_VALUE, CREDIT_SERVICES, COLOR_OFFERS, PROPERTY_CH_MERCHANT_NAME, PROPERTY_NALICHIE_NAME,
    OLD_PRICE, OLD_PRICE_FORMATED, PRICE, PRICE_FORMATED, rootClassName,
  } = props;

  const {isCorpClient} = useContext(UserContext);

  const basket = useContext(BasketContext);

  const [inBasket, setInBasket] = useState(Array.isArray(basket.products) &&
    basket.products.find((element, index) => {
      return Number(element.productId) === Number(ID);
    }) !== undefined);

  useEffect(() => {
    setInBasket(Array.isArray(basket.products) &&
    basket.products.find((element, index) => {
      return Number(element.productId) === Number(ID);
    }) !== undefined)
  }, [basket])

  const defaultRootClassName = `flex flex-col shrink-0 w-[171px] py-4 px-3.5 border ${!last
    ? 'border-r-0'
    : ''} border-solid border-grey-5 xl:basis-1/4 xl:max-w-[calc(25%-1px)] 2xl:basis-1/5 2xl:max-w-[calc(20%-1px)] desktop:px-10 desktop:py-6`;

  const title = props[`NAME_${lang.toUpperCase()}`];

  // определение финальной цены в зависимости от групп клиента
  const finalPrice = isCorpClient && CORP_PRICE && CORP_PRICE < PRICE ? CORP_PRICE : PRICE;
  const finalPriceFormated = isCorpClient && CORP_PRICE && CORP_PRICE < PRICE ? CORP_PRICE_FORMATED : PRICE_FORMATED;

  const basePrice = BASE_PRICE;
  const discount = basePrice - finalPrice;
  // форматируем скидку к общему виду с разделением по пробелам
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  const discountFormated = `-${formatNumber(Math.round(discount))} ₴`;
  const discountPercent = discount > 0 ? Math.round((discount / basePrice) * 100) : 0;
  const discountPercentFormated = `- ${discountPercent} %`;

  const oldPriceDecoration = isCorpClient ? 'decoration-purple-corporate' : 'decoration-red-line-through'

  //console.info(title, basePrice, discount, discountPercent, finalPrice, finalPriceFormated);

  const onClick  = (item_id, url) => {
    analytics.selectItem(item_id, gaParams.item_list_name, gaParams.item_list_id)
    .then(response => {
      analytics.select_item(response.data.params);
      window.location.href = url
    })
    .catch(reason => {
      console.error(reason)
      window.location.href = url
    })
  }

  return (
    <li className={rootClassName ? rootClassName : defaultRootClassName}
        key={itemKey}>
      {/*<!-- картинка и лейблы -->*/}
      <div className="flex items-center justify-center relative">
        <a href={URL}
           onClick={(e) => {
             e.preventDefault();
             onClick(ID, URL);
           }}
           className="flex items-center justify-center desktop:w-[235px] desktop:h-[235px]">
          <img src={IMAGE_379_SRC}
               title={title}
               alt={title}
               width="230"
               height="230"
               loading="lazy"
               className="object-fill w-full h-auto"/>
        </a>
        <ul className="absolute top-0 left-0 flex flex-col gap-1.5">
          <li key={`${itemKey}-labels`}>
            {
              IS_NEW ?
                <span
                  className="bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                  {Lang.getMessage('new')}
                </span> :
                IS_INCLUDED_IN_DEAL && SPECIAL_DEAL &&
                SPECIAL_DEAL.hasOwnProperty('TITLE') ?
                  <span
                    className="hidden bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                      {SPECIAL_DEAL.TITLE}
                  </span> :
                  IS_TOP ?
                    <span
                      className="bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                        {Lang.getMessage('topSales')}
                    </span> :
                    <></>

            }
          </li>
          {
            discountPercent > 0 && (
              <li key={`${itemKey}-discount`}>
                <span
                  className="bg-red-percent px-2 rounded-2 text-white text-[12px] leading-[24px] inline-block font-semibold">
                    {discountFormated}
                </span>
              </li>
            )
          }
        </ul>
        {/*<!-- акция -->*/
          SPECIAL_DEAL && SPECIAL_DEAL.hasOwnProperty('PICTURE') && (
            <img src={SPECIAL_DEAL.PICTURE.src}
                 className="object-cover absolute bottom-0 left-0 h-10 w-10 desktop:w-14 desktop:h-14"/>
          )
        }
      </div>
      {/*<!-- наименование и артикул -->*/}
      <div className="mt-4 flex flex-col">
        <span className="text-[11px] leading-[14px] h-[14px] text-grey-80">
            {PROPERTY_CML2_ARTICLE_VALUE}
        </span>
        <a href={URL}
           onClick={(e) => {
             e.preventDefault();
             onClick(ID, URL);
           }}
           className="text-[14px] leading-[18px] text-grey-90 line-clamp-3 h-[54px]">
          {title}
        </a>
      </div>
      {/*<!-- банки и цвета -->*/}
      <div className="mt-4 flex justify-between items-center h-6">
        <ul className={`flex gap-1 ${Array.isArray(CREDIT_SERVICES) && CREDIT_SERVICES.length > 0 ? '' : 'hidden'}`} key={`banks-${itemKey}`}>
          {
            Array.isArray(CREDIT_SERVICES) && CREDIT_SERVICES.length > 0 && (
              CREDIT_SERVICES.map((service, index) => (
                <li className="w-6 h-6" key={`service-${itemKey}-${index}`}>
                  <img src={service.image_src}
                       className="object-fill"
                       width="24px"
                       height="24px"
                       loading="lazy"
                       title={service.title}
                       alt={`${service.name} - ${service.title}`}/>
                </li>
              ))
            )
          }
        </ul>
        <ul className="flex gap-1 justify-end" key={`offers-for-${itemKey}-${ID}`}>
          {
            COLOR_OFFERS && Array.isArray(COLOR_OFFERS) ?
              <>
                {
                  COLOR_OFFERS.length > 0 && (
                    COLOR_OFFERS.map((offer, index) => {
                      let updatedClassName = offer.CLASS_NAME.replace(/\s+/g, '');
                      return index < 2 ?
                        <li key={`offer-${itemKey}-${index}`}
                            className={`rounded-full w-[18px] h-[18px] ${updatedClassName}`}>
                          <a href={offer.URL}
                             onClick={(e) => {
                               e.preventDefault();
                               onClick(offer.URL, URL);
                             }}
                             className="w-full h-full inline-block"/>
                        </li> :
                        <></>;
                    })
                  )
                }
                {
                  COLOR_OFFERS.length > 2 && (
                    <span className="text-[12px] leading-[18px]"
                          key={`offer-${itemKey}-more`}>
                      {`+${COLOR_OFFERS.length - 2}`}
                  </span>
                  )
                }
              </> :
              <></>
          }
        </ul>
      </div>
      {/*<!-- стоимость и кнопка купить -->*/}
      <div className="mt-4 flex justify-between items-end h-11 flex-wrap gap-1">
        <div className="flex flex-col">
          {
              Number(BASE_PRICE) > 0 && Number(BASE_PRICE) > finalPrice && (
                <span className={`text-[12px] leading-[15px] h-[15px] line-through text-grey-50 ${oldPriceDecoration} desktop:text-[14px] desktop:leading-[17px] desktop:h-[17px]`}>
                    {BASE_PRICE_FORMATED}
                </span>
            )
          }
          {
            finalPrice > 0 && (
              <span
                className="text-[16px] leading-[20px] h-5 font-semibold text-grey-90 desktop:text-[20px] desktop:leading-[24px] desktop:h-6">
                  {finalPriceFormated}
              </span>
            )
          }
        </div>
        {
          PROPERTY_NALICHIE_NAME === 'В наличии' ?
            <button type="button"
                    className={`flex items-center justify-center bg-blue-primary p-0 border-0 w-11 h-[34px] desktop:w-14 desktop:h-11 ${hoverBtnClass}`}>
              {
                inBasket ?
                  <Icon name={'in-cart-icon'}
                        className="h-[22px] w-[29px]"
                        onClick={event => {
                          eventEmitter.emit('openSmallCart');
                        }}/> :
                  <Icon name={'add-to-cart-icon'}
                        className="h-[33px] w-[33px]"
                        onClick={event => {
                          eventEmitter.emit('addToCart', {productId: Number(ID)});
                        }}/>
              }
            </button> :
            <></>
        }

        {
          PROPERTY_NALICHIE_NAME === 'Ожидается' && (
            <a href="#" data-container="buy" data-product-id={ID} data-use="pre-order"
               className={`${finalPrice > 0 ? 'w-full !text-[11.5px] desktop:w-auto' : 'w-full !text-[12px]'} btn btn-block btn-border !py-[9px] !px-[5px] desktop:!py-[14px]`}><span onClick={event => {
              event.preventDefault();
              eventEmitter.emit('preOrder', {productId: ID, productMerchant: PROPERTY_CH_MERCHANT_NAME})
            }}>{Lang.getMessage('pre-order')}</span></a>
          )
        }

        {
          PROPERTY_NALICHIE_NAME === 'Нет в наличии' ?
            <a href="#" data-container="buy" data-product-id={ID} data-use="report"
               className="btn btn-block btn-border not-available-btn !pt-[4px] !pb-[2px] !px-[8px] desktop:!pt-[10px] desktop:!pb-[7px] desktop:!px-[14px]">
              <svg onClick={event => {
                event.preventDefault();
                eventEmitter.emit('reportAvailability', {productId: ID, productMerchant: PROPERTY_CH_MERCHANT_NAME});
              }} xmlns="http://www.w3.org/2000/svg" width="25" height="22" fill="none">
                <path
                  d="m1.95 3.303 8.074 5.768c.53.379 1.15.568 1.771.568.62 0 1.242-.19 1.77-.568l8.074-5.768c.17.332.268.706.268 1.104v3.996h1.084V4.407a3.515 3.515 0 0 0-3.51-3.51H4.108a3.514 3.514 0 0 0-3.511 3.51v9.643a3.515 3.515 0 0 0 3.51 3.51h6.164v-1.083H4.11a2.43 2.43 0 0 1-2.427-2.427V4.407c0-.398.097-.772.267-1.104zM19.481 1.98c.548 0 1.052.184 1.458.492L12.936 8.19c-.683.487-1.6.487-2.283 0L2.649 2.472a2.41 2.41 0 0 1 1.458-.492h15.375z"
                  fill="#0080CC"></path>
                <path
                  d="M24.21 19.29c0-1.194-.6-1.965-1.102-2.401a1.261 1.261 0 0 1-.44-.95v-1.926c0-2.293-1.711-4.195-3.914-4.475V8.495h-1.083v1.041c-2.201.278-3.913 2.181-3.913 4.475v1.927c0 .36-.161.706-.441.95-.503.436-1.102 1.206-1.102 2.4v.542h3.665a2.346 2.346 0 0 0 2.332 2.126c1.22 0 2.223-.936 2.333-2.126h3.667v-.542l-.002.001zm-5.999 1.584a1.26 1.26 0 0 1-1.24-1.043h2.481a1.262 1.262 0 0 1-1.24 1.043h-.001zm-4.85-2.126c.103-.398.326-.748.663-1.04a2.34 2.34 0 0 0 .814-1.768v-1.927c0-1.884 1.514-3.42 3.37-3.427 1.859.006 3.373 1.543 3.373 3.427v1.927c0 .673.297 1.319.814 1.768.339.294.56.642.664 1.041h-9.697v-.001z"
                  fill="#0080CC"></path>
              </svg>
            </a> :
          <></>
        }
      </div>
      {/*<!-- кешбек -->*/
        PROPERTY_CASHBACK_VALUE && PROPERTY_CASHBACK_VALUE > 0 && (
          <span className="mt-2 text-grey-90 text-[12px] leading-[15px] h-[15px]">
            {`${Lang.getMessage('cashback')} ${CASHBACK_FORMATED}`}
          </span>
        )
      }
    </li>
  );
}

export default Product;